import React from 'react';
import firebase from 'firebase/app';

import { User } from '../contexts/user';

const useAccountStatus = () => {
  const [accountStatus, setAccountStatus] = React.useState();
  const user = React.useContext(User);
  const uid = user?.uid;

  React.useEffect(() => {
    if (!uid) {
      return;
    }

    const accountStatusRef = firebase
      .database()
      .ref(`userData/${uid}/accountStatus`);

    const cb = (snapshot) => {
      setAccountStatus(snapshot.val());
    };

    accountStatusRef.on('value', cb);

    return () => {
      accountStatusRef.off('value', cb);
    };
  }, [uid]);

  return accountStatus;
};

export default useAccountStatus;
