import firebase from 'firebase/app';

export const getPlanSubscription = async () => {
  const subscription = await firebase
    .functions()
    .httpsCallable('getPlanSubscription')();

  return subscription.data;
};

export const createPlanSubscription = async ({ priceId, paymentMethodId }) => {
  const subscription = await firebase
    .functions()
    .httpsCallable('createPlanSubscription')({ priceId, paymentMethodId });

  return subscription.data;
};

export const updatePlanSubscription = async ({ priceId, paymentMethodId }) => {
  if (!priceId && !paymentMethodId) {
    throw new Error(
      'Failed to update plan subscription: Neither priceId nor paymentMethodId provided.'
    );
  }

  const res = await firebase
    .functions()
    .httpsCallable('updatePlanSubscription')({
    priceId,
    paymentMethodId,
  });

  return res.data;
};

export const cancelPlanSubscription = async () => {
  const res = await firebase
    .functions()
    .httpsCallable('cancelPlanSubscription')();

  return res.data;
};

export const resubscribe = async () => {
  const res = await firebase.functions().httpsCallable('resubscribe')();

  return res.data;
};
