import React from 'react';
import useSWR from 'swr';

import { User } from '../contexts/user';

import { getPlanSubscription } from '../services/planSubscription';

const usePlanSubscription = () => {
  const user = React.useContext(User);
  const {
    data: planSubscription,
    isValidating,
    mutate,
  } = useSWR(user ? 'getPlanSubscription' : null, getPlanSubscription, {
    revalidateOnFocus: false,
  });

  return {
    planSubscription,
    isValidatingPlanSubscription: isValidating,
    refreshPlanSubscription: mutate,
  };
};

export default usePlanSubscription;
