import React from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/storage';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Head from 'next/head';

import config from '../config';

import { icons } from '../assets/icons';

import ErrorBoundary from '../components/ErrorBoundary';

import { User, UserDispatch } from '../contexts/user';

import { useUser } from '../hooks';

import '../styles/style.scss';

// client, server rendering -> two instances are created, need just one
if (firebase.apps.length === 0) {
  firebase.initializeApp(config.firebase);
}

if (process.env.NODE_ENV !== 'production') {
  firebase.auth().useEmulator('http://localhost:9099');
  firebase.database().useEmulator('localhost', 9000);
  firebase.functions().useEmulator('localhost', 5001);
  firebase.storage().useEmulator('localhost', 9199);

  // self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

// firebase.appCheck().activate('RECAPTCHA_SITE_KEY', true);

React.icons = icons;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(config.stripePublishableKey);

const App = ({ Component, pageProps }) => {
  const [user, userDispatch] = useUser();

  return (
    <UserDispatch.Provider value={userDispatch}>
      <User.Provider value={user}>
        <ErrorBoundary>
          <Elements stripe={stripePromise}>
            <Head>
              <script
                src={`https://www.googletagmanager.com/gtag/js?id=${config.analyticsMeasurementId}`}
                async
              />
            </Head>
            <Component {...pageProps} />
          </Elements>
        </ErrorBoundary>
      </User.Provider>
    </UserDispatch.Provider>
  );
};

export default App;
