import useSWRImmutable from 'swr/immutable';

import { getPlans } from '../services/plan';

const usePlans = () => {
  const { data: plans } = useSWRImmutable('getPlans', getPlans);

  return { plans };
};

export default usePlans;
