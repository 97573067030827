import React from 'react';
import { CAlert } from '@coreui/react';

import ContentContainer from './ContentContainer';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <ContentContainer>
          <CAlert className="text-center" color="danger">
            <span className="font-weight-bold">
              Something went wrong, please try to reload the page.
            </span>
          </CAlert>
        </ContentContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
