import firebase from 'firebase/app';

export const getPlans = async () => {
  const plans = await firebase.functions().httpsCallable('getPlans')();

  return plans.data;
};

export const getPlan = async ({ id }) => {
  const plan = await firebase
    .database()
    .ref(`plans/${id}`)
    .get()
    .then((snapshot) => snapshot.val());

  return plan;
};
