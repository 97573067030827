import React from 'react';

const ContentContainer = ({ children }) => {
  return (
    <div className="mx-auto" style={{ maxWidth: '40rem' }}>
      {children}
    </div>
  );
};

export default ContentContainer;
