import { usePlans, useUserData } from './index';

const useCurrentPlan = () => {
  const { plans } = usePlans();
  const userData = useUserData();

  const currentPlan = plans?.find((p) => p.id === userData?.plan);

  return currentPlan;
};

export default useCurrentPlan;
