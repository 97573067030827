import {
  cilCloudUpload,
  cilMovie,
  cilUser,
  cilCheckAlt,
  cilX,
  cilCreditCard,
  cilVolumeOff,
  cilLoop,
  cilMediaPlay,
  cilCheckCircle,
} from '@coreui/icons';

import { logo } from './logo';
import { logoLetter } from './logo-letter';
import { help } from './help';

export const icons = {
  logo,
  logoLetter,
  help,
  cilCloudUpload,
  cilMovie,
  cilUser,
  cilCheckAlt,
  cilX,
  cilCreditCard,
  cilVolumeOff,
  cilLoop,
  cilMediaPlay,
  cilCheckCircle,
};
