import React from 'react';
import firebase from 'firebase/app';

import { User } from '../contexts/user';

const usePlanUsage = () => {
  const [usage, setUsage] = React.useState();
  const user = React.useContext(User);
  const uid = user?.uid;

  React.useEffect(() => {
    if (!uid) {
      return;
    }

    const usageRef = firebase.database().ref(`usage/${uid}`);

    const cb = (snapshot) => {
      setUsage(snapshot.val());
    };

    usageRef.on('value', cb);

    return () => {
      usageRef.off('value', cb);
    };
  }, [uid]);

  return usage;
};

export default usePlanUsage;
