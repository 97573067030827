import config from '../config';

export const loggerSettings = {
  name: 'app',
  level: config.logLevel,
};

export const MIN_PASSWORD_LENGTH = 8;

export const VIDEO_BATCH_SIZE = 24;
