const config = {
  logLevel: process.env.NEXT_PUBLIC_LOGGER_LOG_LEVEL,
  stripePublishableKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  videoPageUrl: process.env.NEXT_PUBLIC_VIDEO_PAGE_URL,
  firebase: {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  },
  analyticsMeasurementId: process.env.NEXT_PUBLIC_ANALYTICS_MEASUREMENT_ID,
  adsMeasurementId: process.env.NEXT_PUBLIC_ADS_MEASUREMENT_ID,
};

export default config;
