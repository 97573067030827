import React from 'react';
import firebase from 'firebase/app';

import { User } from '../contexts/user';

import { VIDEO_BATCH_SIZE } from '../settings';

const snapshotToArray = (snapshot) => {
  const res = [];

  snapshot.forEach((childSnapshot) => {
    res.push(childSnapshot.val());
  });

  return res;
};

const useVideos = () => {
  const user = React.useContext(User);
  const [videos, setVideos] = React.useState([]);
  const [loadingVideos, setLoadingVideos] = React.useState(true);
  const [allVideosLoaded, setAllVideosLoaded] = React.useState(false);
  const uid = user?.uid;

  const loadNextVideos = async () => {
    setLoadingVideos(true);

    const newVideos = await firebase
      .database()
      .ref(`videos/${uid}`)
      .orderByKey()
      .endBefore(videos[videos.length - 1].id)
      .limitToLast(VIDEO_BATCH_SIZE)
      .get()
      .then(snapshotToArray);
    const newVideosDesc = newVideos.reverse();

    if (newVideosDesc.length > 0) {
      setVideos([...videos, ...newVideosDesc]);
    }

    setLoadingVideos(false);
    setAllVideosLoaded(newVideosDesc.length < VIDEO_BATCH_SIZE);
  };

  React.useEffect(() => {
    if (!uid) {
      return;
    }

    const videosRef = firebase.database().ref(`videos/${uid}`);

    const handleVideoChange = (childSnapshot) => {
      const video = childSnapshot.val();

      setVideos((vids) => {
        const i = vids.findIndex((v) => v.id === video.id);
        return [...vids.slice(0, i), video, ...vids.slice(i + 1)];
      });
    };

    const initVideos = async () => {
      const recentVideos = await videosRef
        .limitToLast(VIDEO_BATCH_SIZE)
        .get()
        .then(snapshotToArray);
      const recentVideosDesc = recentVideos.reverse();

      setVideos(recentVideosDesc);
      setLoadingVideos(false);
      setAllVideosLoaded(recentVideosDesc.length < VIDEO_BATCH_SIZE);

      videosRef.on('child_changed', handleVideoChange);
    };

    initVideos();

    return () => {
      videosRef.off('child_changed', handleVideoChange);
    };
  }, [uid]);

  return {
    videos,
    loadingVideos,
    allVideosLoaded,
    loadNextVideos,
  };
};

export default useVideos;
