import * as actions from '../actions/user';

export default function reducer(state, action) {
  switch (action.type) {
    case actions.USER_SIGNED_IN:
      return action.user;

    case actions.USER_SIGNED_OUT:
      return null;

    default:
      throw new Error(`Unknown user action: ${action.type}`);
  }
}
