import React from 'react';
import firebase from 'firebase/app';

import { USER_SIGNED_IN, USER_SIGNED_OUT } from '../actions/user';

import userReducer from '../reducers/user';

const useUser = () => {
  const [user, dispatch] = React.useReducer(userReducer);

  React.useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        dispatch({ type: USER_SIGNED_IN, user });
      } else {
        dispatch({ type: USER_SIGNED_OUT });
      }
    });
  }, []);

  return [user, dispatch];
};

export default useUser;
