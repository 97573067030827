import config from '../config';

function gtag() {
  window.dataLayer.push(arguments);
}

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];

  gtag('js', new Date());
  gtag('config', config.analyticsMeasurementId, {
    send_page_view: false,
  });
  gtag('config', config.adsMeasurementId, {
    send_page_view: false,
  });
}

const useGtag = () => {
  return gtag;
};

export default useGtag;
